import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import img1 from '../assets/1.webp';
import img2 from '../assets/2.webp';
import img3 from '../assets/3.webp';
import img4 from '../assets/4.webp';
import logo from '../assets/logo1.png';
import SkyImg from '../assets/sky_linekotsi_04.jpg';
import ElementInternal from './components/EntranceAnimation';
import TabShow from './components/TabShow';

import './home.css';

const Home = () => {
  const [showAnimation, setShowAnimation] = useState(true);
  const [headerClassName, setHeaderClassName] = useState('');

  useEffect(() => {
    const scalc = window.innerWidth / 1920;
    document
      .getElementsByTagName('html')[0]
      .style.setProperty('font-size', scalc + 'px');
    AOS.init({ duration: 1500 });

    const event = window.addEventListener('scroll', (e) => {
      // 滚动的高度(兼容多种浏览器)
      const scrollTop =
        (e.srcElement ? e.srcElement.documentElement.scrollTop : false) ||
        window.pageYOffset ||
        (e.srcElement ? e.srcElement.body.scrollTop : 0);
      if (scrollTop > 450 * scalc) {
        setHeaderClassName('border');
      }
      if (scrollTop < 450 * scalc) {
        setHeaderClassName('');
      }
    });
    // setTimeout(() => {
    //   setShowAnimation(false);
    // }, 4500);

    return () => {
      window.removeEventListener(event);
    };
  }, []);

  const header = () => {
    return (
      <div className={`header ${headerClassName}`}>
        <img src={logo} alt='' className='home-logo' />
      </div>
    );
  };

  const contentTitleBody = () => {
    return (
      <div className='content-title-body'>
        <div className='home-title'>
          <div
            data-aos='fade-up'
            data-aos-delay='200'
            data-aos-offset={10}
            data-aos-duration='600'
          >
            妙语自生
          </div>
          <div
            data-aos='fade-up'
            data-aos-delay='400'
            data-aos-offset={10}
            data-aos-duration='600'
          >
            创作AI - 自动生成高质量文案、脚本、笔记
          </div>
        </div>

        <div className='title-btn-body'>
          <div
            className='title-btn'
            data-aos='fade-up'
            data-aos-delay='600'
            data-aos-offset={10}
            data-aos-duration='600'
          >
            加入创作
          </div>
        </div>
      </div>
    );
  };

  const contentDetail1 = () => {
    const data = [
      { name: '抖音文案1', img: SkyImg },
      { name: '抖音文案2', img: img1 },
      { name: '抖音文案3', img: img2 },
      { name: '抖音文案4', img: img3 },
      { name: '抖音文案5', img: img4 },
    ];
    return (
      <div className='contentDetail-body'>
        <div
          className='content-title'
          data-aos='fade-up'
          data-aos-offset={10}
          data-aos-duration='600'
        >
          <span>多类型</span>抖音文案生成
        </div>
        <TabShow type='top' data={data} width={1200} />
      </div>
    );
  };

  const contentDetail2 = () => {
    const data = [
      { name: '抖音文案1', img: SkyImg },
      { name: '抖音文案2', img: img1 },
      { name: '抖音文案3', img: img2 },
      { name: '抖音文案4', img: img3 },
      { name: '抖音文案5', img: img4 },
    ];
    return (
      <div className='contentDetail-body'>
        <div
          className='content-title'
          data-aos='fade-up'
          data-aos-offset={10}
          data-aos-duration='600'
        >
          <span>多类型</span>抖音分镜文案生成
        </div>
        <TabShow type='left' data={data} width={1200} />
      </div>
    );
  };

  const contentDetail3 = () => {
    const data = [
      { name: '抖音文案1', img: SkyImg },
      { name: '抖音文案2', img: img1 },
      { name: '抖音文案3', img: img2 },
      { name: '抖音文案4', img: img3 },
      { name: '抖音文案5', img: img4 },
    ];
    return (
      <div className='contentDetail-body'>
        <div
          className='content-title'
          data-aos='fade-up'
          data-aos-offset={10}
          data-aos-duration='600'
        >
          <span>多类型</span>小红书文案生成
        </div>
        <TabShow type='right' data={data} width={1200} />
      </div>
    );
  };

  const renderOurAdvantage = () => {
    const data = [
      {
        title: '标题介绍',
        text: '具体文案具体文案具体文案具体文案具体文案',
        img: logo,
      },
      {
        title: '标题介绍',
        text: '具体文案具体文案具体文案具体文案具体文案',
        img: logo,
      },
      {
        title: '标题介绍',
        text: '具体文案具体文案具体文案具体文案具体文案',
        img: logo,
      },
      {
        title: '标题介绍',
        text: '具体文案具体文案具体文案具体文案具体文案',
        img: logo,
      },
      {
        title: '标题介绍',
        text: '具体文案具体文案具体文案具体文案具体文案',
        img: logo,
      },
    ];

    return (
      <div className='ourAdvantage-body'>
        {data.map(({ title, text, img }, index) => {
          return (
            <div className='ourAdvantage-item' key={index}>
              <div className='ourAdvantage-item-img'>
                <img
                  src={img}
                  alt=''
                  data-aos='fade-up'
                  data-aos-delay={index * 40}
                  data-aos-offset={10}
                  data-aos-duration='600'
                />
              </div>

              <div
                className='ourAdvantage-item-title'
                data-aos='zoom-in-right'
                data-aos-delay={index * 40}
                data-aos-offset={10}
                data-aos-duration='600'
              >
                {title}
              </div>

              <div
                className='ourAdvantage-item-text'
                data-aos='zoom-in-right'
                data-aos-delay={index * 40}
                data-aos-offset={10}
                data-aos-duration='600'
              >
                {text}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderContactUs = () => {
    return (
      <div className='contact-us-body'>
        <img
          src={logo}
          alt=''
          data-aos='fade-up'
          data-aos-offset={10}
          data-aos-duration='600'
        />
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className='footer'>
        <div></div>
        <a href='https://beian.miit.gov.cn'>闽ICP备2022013133号-1</a>
      </div>
    );
  };

  return (
    <div className='home'>
      {showAnimation && <ElementInternal />}
      {!showAnimation && (
        <div>
          {header()}
          {contentTitleBody()}
          <div className='home-content'>
            <div className='content-detail'>
              {contentDetail1()}
              {contentDetail2()}
              {contentDetail3()}

              <div className='ourAdvantage'>
                <div
                  className='content-title'
                  data-aos='fade-up'
                  data-aos-offset={10}
                  data-aos-duration='600'
                >
                  我们的<span>优势</span>
                </div>
                {renderOurAdvantage()}
              </div>

              <div className='contact-us'>
                <div
                  className='content-title'
                  data-aos='fade-up'
                  data-aos-offset={10}
                  data-aos-duration='600'
                >
                  <span>联系</span>我们
                </div>
                {renderContactUs()}
              </div>
            </div>
            {renderFooter()}
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
