import { useEffect } from 'react';
import Typed from 'typed.js';
import { Image } from 'antd';

import title_logo from '../../assets/title_logo.png';

import './EntranceAnimation.css';

const jumpToScriptPath = () => {
  window.open('https://script.selftext.net/', '_blank');
};

const EntranceAnimation = () => {
  useEffect(() => {
    const options1 = {
      strings: [
        `<span style='background: linear-gradient(102deg, #FF2727 -13%, #fb7e7e 109%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;background-clip: text;'>创作AI</span>` +
          `<span> - 自动生成高质量文案、脚本、笔记</span>`,
      ],
      typeSpeed: 120, // 打印速度
      startDelay: 1000, // 开始之前的延迟300毫秒
      loop: false, // 是否循环
    };
    new Typed('#typed1', options1);

    const options2 = {
      strings: [
        `<span style='color: #0092df; font-weight: bold;'>Self</span>` +
          `<span style='color: #333;  font-weight: bold;'>text</span>`,
      ],
      typeSpeed: 80, // 打印速度
      startDelay: 100, // 开始之前的延迟300毫秒
      loop: false, // 是否循环
      showCursor: false, // showCursor show cursor
    };
    new Typed('#typed2', options2);
  }, []);
  return (
    <div className='combined-wrapper'>
      <div className='combined-animation-div'>
        <div className='combined-animation-logo-div'>
          <div
            data-aos='fade-left'
            data-aos-delay='100'
            data-aos-offset={10}
            data-aos-duration='600'
          >
            <Image src={title_logo} height={'140rem'} preview={false} />
          </div>
          <div
            id='typed2'
            style={{
              whiteSpace: 'pre-wrap',
              display: 'flex',
              alignItems: 'center',
              marginLeft: '20rem',
            }}
          ></div>
        </div>
        <div className='combined-animation-slogan-div'>
          <span id='typed1' style={{ whiteSpace: 'pre-wrap' }}></span>
        </div>
      </div>
      <div
        className='combined-title-btn'
        data-aos='fade-left'
        data-aos-delay={3000}
        data-aos-offset={10}
        data-aos-duration='700'
        onClick={jumpToScriptPath}
      >
        开始创作
      </div>
      <div className='footer'>
        <div></div>
        <a href='https://beian.miit.gov.cn'>闽ICP备2022013133号-1</a>
      </div>
    </div>
  );
};

export default EntranceAnimation;
