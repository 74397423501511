import { Carousel } from "antd";
import { useRef, useState } from "react";

import "./tabShow.css";

const autoplaySpeed = 1000 * 10;

const TabShow = ({ type, data, width }) => {
  const [number, setNumber] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);

  const carouselRef = useRef();

  const changeTab = (newNumber) => {
    setNumber(newNumber);
    // carouselRef.current.goTo(newNumber);
  };

  const tab = () => {
    let direction = "";
    if (type === "top") {
      direction = "up";
    }
    if (type === "left") {
      direction = "right";
    }
    if (type === "right") {
      direction = "left";
    }
    return (
      <div
        className={`${type}-tab`}
        data-aos={`fade-${direction}`}
        data-aos-offset={10}
        data-aos-duration="600"
      >
        {data.map(({ name }, index) => {
          return (
            <div
              key={index}
              className={`${index === number ? "" : type + "-item"} ${
                index === number ? type + "-selecd" : ""
              }`}
              onMouseEnter={() => {
                setNumber(index);
                carouselRef.current.goTo(index);
                setAutoPlay(false);
              }}
              onMouseLeave={() => {
                setAutoPlay(true);
              }}
            >
              {name}
            </div>
          );
        })}
      </div>
    );
  };

  const imgBody = () => {
    let direction = "";
    if (type === "top") {
      direction = "up";
    }
    if (type === "left") {
      direction = "left";
    }
    if (type === "right") {
      direction = "right";
    }
    return (
      <div
        className={`${type}-imgbody`}
        data-aos={`fade-${direction}`}
        data-aos-offset={10}
        data-aos-duration="600"
      >
        <Carousel
          dotPosition={type}
          dots={false}
          autoplay={autoPlay}
          autoplaySpeed={autoplaySpeed}
          afterChange={changeTab}
          ref={carouselRef}
        >
          {data.map(({ img }, index) => {
            return (
              <div key={index}>
                <img src={img} alt="" className={`${type}-img`} />
              </div>
            );
          })}
        </Carousel>
      </div>
    );
  };
  return (
    <div style={{ width: width + "rem" }} className={type}>
      {(type === "top" || type === "left") && tab()}
      {imgBody()}
      {type === "right" && tab()}
    </div>
  );
};

export default TabShow;
